export { default } from "./[slug]";
import { type GetStaticProps } from "next";

import { type GetSlugPagePropsResult, getStaticProps as getStaticPropsSlug } from "./[slug]";

export const getStaticProps: GetStaticProps<GetSlugPagePropsResult, Record<string, never>> = async ({ draftMode, locale, params }) =>
{
  return getStaticPropsSlug({
    draftMode,
    locale,
    params: {
      ...params,
      slug: undefined,
    }
  });
};
